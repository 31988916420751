<template>
  <el-container class="container">
    <el-header class="header">{{title}}</el-header>
    <el-main class="main">
      <el-row class="content">
        <iframe :src="sourceContent" allowfullscreen="true" style="width: 100%;height: 900px;"></iframe>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import { setToken, getToken,setCookies } from '@/utils/auth'

export default {
  data () {
    return {
      sourceContent: '',
      title: ''
    }
  },
  created () {
    let token = getToken()
    setToken(token)
    setCookies('courseware_id', this.$route.query.id)
    this.sourceContent = process.env.VUE_APP_PREVIEW_ADDRESS + this.$route.query.sourceContent
    this.title = this.$route.query.title
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #e5e5e5;
  height: 100%;
  padding: 0 30px;
  .header {
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }
  .main {
    background: #fff;
    overflow: auto;
    padding: 0;
    margin-bottom: 70px;
    .content {
      min-width: 992px;
      max-width: 1400px;
      margin: 0 auto 0;
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: justify;
      color: #616161;
    }
  }
}

/deep/ .el-page-header__left:hover {
  color: #f5c319;
}

.footer {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 9;
  background: #e5e5e5;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-right: 50px;
  flex-direction: row-reverse;
}
</style>
