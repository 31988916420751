var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { staticClass: "container" },
    [
      _c("el-header", { staticClass: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "el-main",
        { staticClass: "main" },
        [
          _c("el-row", { staticClass: "content" }, [
            _c("iframe", {
              staticStyle: { width: "100%", height: "900px" },
              attrs: { src: _vm.sourceContent, allowfullscreen: "true" },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }